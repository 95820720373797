(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ReceivingClientController', ReceivingClientController);

    ReceivingClientController.$inject = ['ReceivingClient', 'Company', '$mdDialog', '$scope', '$state', 'House', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal', '$rootScope', 'CommonMethods', 'Modal', 'globalCompany', 'ElectronicBillIdentificationType', 'FiscalTerritorialDivision'];

    function ReceivingClientController(ReceivingClient, Company, $mdDialog, $scope, $state, House, ParseLinks, AlertService, paginationConstants, pagingParams, Principal, $rootScope, CommonMethods, Modal, globalCompany, ElectronicBillIdentificationType, FiscalTerritorialDivision) {
        var vm = this;
        $rootScope.active = "feClients";
        $rootScope.mainTitle = "Clientes de facturación";
        vm.isReady = false;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.estado = "";
        vm.receivingClients = [];
        vm.ocultarACondos = true;
        vm.estadoTemporal = "2";
        vm.filter = {
            houseNumber: " ",
            desocupated: "empty"
        };
        vm.itemsPerPage = 12;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.houses = [];
        vm.housesDesorder = [];

        vm.excelData = [];
        vm.file = null;
        vm.housesList = [];

        $scope.$applyAsync(function () {
            vm.excelData = XLSX.utils.sheet_to_json(firstSheet);
        });


        vm.electronicBillIdentificationTypes = [];
        vm.loadAllElectronicBillIdentificationType = loadAllElectronicBillIdentificationType;

        function loadAllElectronicBillIdentificationType() {
            ElectronicBillIdentificationType.query({}, function (data) {
                vm.electronicBillIdentificationTypes = data;
                vm.mapIdentificationTypes();
            }, function (error) {
                AlertService.error(error.data.message);
            });
        }

        vm.mapIdentificationTypes = function () {
            if (!vm.excelData || !vm.electronicBillIdentificationTypes) {
                return;
            }

            for (var i = 0; i < vm.excelData.length; i++) {
                var client = vm.excelData[i];

                for (var j = 0; j < vm.electronicBillIdentificationTypes.length; j++) {
                    var type = vm.electronicBillIdentificationTypes[j];

                    if (type.description.toLowerCase() === client.tipoIdentificacion.toLowerCase()) {
                        client.identificationTypeId = type.id;
                        break;
                    }
                }
            }
        };

        loadAllFiscalTerritorialDivision();

        // Función principal para cargar la división territorial
        function loadAllFiscalTerritorialDivision() {
            FiscalTerritorialDivision.query({size: 8000}, onSuccess, onError);

            function onSuccess(data) {
                vm.fiscalTerritorialDivision = data;

                vm.provinceList = data.filter(function(item) {
                    return item.territorialType === 'Provincia';
                });

                vm.cantonList = data.filter(function(item) {
                    return item.territorialType === 'Cantón';
                });

                vm.districtList = data.filter(function(item) {
                    return item.territorialType === 'Distrito';
                });

               // Ejecutar mapeo de datos
                vm.mapProvincias();
                vm.mapCantones();
                vm.mapDistritos();

                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.mapProvincias = function () {
            if (!vm.excelData || vm.excelData.length === 0) {
                return;
            }

            angular.forEach(vm.excelData, function (client) {
                if (!client.provincia) return;

                var match = null;
                for (var i = 0; i < vm.provinceList.length; i++) {
                    if (vm.provinceList[i].name.trim().toLowerCase() === client.provincia.trim().toLowerCase()) {
                        match = vm.provinceList[i];
                        break;
                    }
                }

                if (match) {
                    client.province = match;
                    vm.filterCanton(client);
                } else {
                }
            });
        };

        vm.filterCanton = function (row) {
            if (!row.province) {
                row.cantonList = [];
                row.districtList = [];
                return;
            }

            row.cantonList = [];
            for (var i = 0; i < vm.fiscalTerritorialDivision.length; i++) {
                var item = vm.fiscalTerritorialDivision[i];
                if (item.belongsToId === row.province.id && item.territorialType === 'Cantón') {
                    row.cantonList.push(item);
                }
            }


            var match = null;
            for (var j = 0; j < row.cantonList.length; j++) {
                if (row.cantonList[j].name.trim().toLowerCase() === (row.canton ? row.canton.trim().toLowerCase() : "")) {
                    match = row.cantonList[j];
                    break;
                }
            }

            if (match) {
                row.canton = match;
                vm.filterDistrict(row);
            } else {
            }
        };

        vm.filterDistrict = function (row) {
            if (!row.canton) {
                row.districtList = [];
                return;
            }

            row.districtList = [];
            for (var i = 0; i < vm.fiscalTerritorialDivision.length; i++) {
                var item = vm.fiscalTerritorialDivision[i];
                if (item.belongsToId === row.canton.id && item.territorialType === 'Distrito') {
                    row.districtList.push(item);
                }
            }


            var match = null;
            for (var j = 0; j < row.districtList.length; j++) {
                if (row.districtList[j].name.trim().toLowerCase() === (row.distrito ? row.distrito.trim().toLowerCase() : "")) {
                    match = row.districtList[j];
                    break;
                }
            }

            if (match) {
                row.district = match;
            } else {
            }
        };

        vm.filterNeighborhood = function (row) {
            if (!row.district) {
                row.neighborhoodList = [];
                return;
            }

            row.neighborhoodList = [];
            for (var i = 0; i < vm.fiscalTerritorialDivision.length; i++) {
                var item = vm.fiscalTerritorialDivision[i];
                if (item.belongsToId === row.district.id && item.territorialType === 'Barrio o Poblado') {
                    row.neighborhoodList.push(item);
                }
            }

            row.neighborhood = null;
        };

        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };


        vm.openExcelModal = function(ev) {
            $mdDialog.show({
                templateUrl: 'app/entities/receiving-client/modal-excel.html',
                scope: $scope,
                preserveScope: true,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: ev,
                onComplete: function () {
                    angular.element('.md-dialog').css('width', '95%');  // Establece el ancho a 95%
                }
            });
        };

        vm.closeDialog = function() {
            $state.go('receiving-client',{},{reload:'receiving-client'});
            $mdDialog.hide();
        };

        vm.onFileChange = function (file) {
            if (file && file.name.endsWith('.xlsx')) {
                vm.uploadExcelData(file);
            } else {
                alert("Por favor, selecciona un archivo Excel válido (.xlsx). ");
            }
        };

        vm.uploadExcelData = function (file) {
            if (file) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var data = e.target.result;
                    try {
                        var workbook = XLSX.read(data, {type: 'binary'});
                        var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                        var jsonData = XLSX.utils.sheet_to_json(firstSheet, {defval: ""});

                        // Definir los campos requeridos
                        var requiredFields = [
                            "Tipo de Identificación",
                            "Identificación",
                            "Nombre",
                            "Correo",
                            "Cliente",
                            "Codigo Interno del Cliente",
                            "Provincia",
                            "Cantón",
                            "Distrito",
                            "Dirección en Detalle"
                        ];

                        // Verificar si todas las columnas requeridas existen en la primera fila del JSON
                        var firstRow = jsonData[0] || {};
                        var missingFields = requiredFields.filter(function (field) {
                            return !firstRow.hasOwnProperty(field);
                        });

                        if (missingFields.length > 0) {
                            alert("El archivo Excel no contiene las siguientes columnas requeridas: \n" + missingFields.join(", "));
                            return;
                        }

                        // Mapear los datos si los campos son válidos
                        vm.excelData = jsonData.map(function (row) {
                            return {
                                tipoIdentificacion: row["Tipo de Identificación"],
                                identificacion: row["Identificación"],
                                nombre: row["Nombre"],
                                correo: row["Correo"],
                                cliente: row["Cliente"],
                                codigoInterno: row["Codigo Interno del Cliente"],
                                provincia: row["Provincia"],
                                canton: row["Cantón"],
                                distrito: row["Distrito"],
                                direccionDetalle: row["Dirección en Detalle"]
                            };
                        });

                        vm.loadHouses();

                        $scope.$applyAsync(function () {
                            vm.mapProvincias();
                        });

                        vm.loadAllElectronicBillIdentificationType();

                        $scope.$apply();
                    } catch (error) {
                        alert("Error al leer el archivo Excel.");
                    }
                };
                reader.readAsBinaryString(file);
            }
        };

        vm.confirmProcess = function () {
            if (!vm.excelData || vm.excelData.length === 0) {
                alert("No hay datos para procesar. Por favor, carga un archivo Excel válido.");
                return;
            }

            Modal.confirmDialog(
                "¿Está seguro que desea cargar los clientes ingresados en la plantilla?",
                "Se agregarán los clientes a la base de datos.",
                function () {
                    Modal.showLoadingBar();
                    var receivingClientDTOs = vm.excelData.map(function (row) {
                        return {
                            id: row.id || null,
                            name: row.nombre,
                            identificationNumber: row.identificacion || null,
                            commercialName: row.nombreComercial || null,
                            direction: row.direccionDetalle || null,
                            email: row.correo || null,
                            ccEmails: row.correosCc || null,
                            internalCode: row.codigoInterno || null,
                            companyId: globalCompany.getId(),
                            houseId: row.cliente,
                            provinceId: row.province ? row.province.id : null,
                            cantonId: row.canton ? row.canton.id : null,
                            districtId: row.district ? row.district.id : null,
                            neighborhoodId: row.neighborhood ? row.neighborhood.id : null,
                            identificationTypeId: row.identificationTypeId || null
                        };
                    });

                    ReceivingClient.saveAll(receivingClientDTOs, function (response) {
                        Modal.toast("Se guardaron los datos correctamente");
                        vm.closeDialog();
                        Modal.hideLoadingBar();
                        loadAll();
                    }, function (error) {
                        Modal.toast("Ocurrio un error guardando los datos");
                    });
                }
            );
        };

        vm.goToDetailReceivingClient = function (house) {
            var id = CommonMethods.encryptIdUrl(house.id);
            $state.go("receiving-client-detail", {houseId: id, houseNumber: house.housenumber});
        };

        vm.close = function () {
            $mdDialog.hide();
        };

        vm.closeAndFilter = function () {
            vm.filterHouses();
            $mdDialog.hide();
        };

        vm.filterHouses = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.houses = [];
            loadAll();
        };

        vm.enabledOptions = true;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.houses = [];


        ReceivingClient.getByCompanyId({ companyId: globalCompany.getId() },
            function (data) {
                vm.receivingClients = data;
                loadAll();
            },
            function (error) {
            }
        );

        vm.loadHouses = function () {
            House.getAllHousesClean({ companyId: globalCompany.getId() }).$promise.then(function (response) {
                vm.housesList = response;
                vm.mapClientsToHouses();
            }).catch(function (error) {
            });
        };


        vm.loadHouses();

        vm.mapClientsToHouses = function () {
            // Verificar si los datos están presentes
            if (!vm.excelData || !vm.housesList) {
                return;
            }

            for (var i = 0; i < vm.excelData.length; i++) {
                var client = vm.excelData[i];

                for (var j = 0; j < vm.housesList.length; j++) {
                    var house = vm.housesList[j];
                    // Asegurarse de que los datos estén correctamente formateados
                    var clientValue = client.cliente ? client.cliente+"".trim().toLowerCase() : "";
                    var houseValue = house.housenumber ? house.housenumber.trim().toLowerCase() : "";

                    if (houseValue === clientValue) {
                        client.cliente = house.id; // Asignar el id de la casa
                        break;
                    }
                }
            }
        };

        function loadAll() {
            if (vm.filter.houseNumber == "" || vm.filter.houseNumber == undefined) {
                vm.filter.houseNumber = " ";
            }
            House.getAllHouses({
                page: vm.page,
                size: 77,
                desocupated: vm.filter.desocupated,
                houseNumber: vm.filter.houseNumber,
                companyId: globalCompany.getId(),
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                angular.forEach(data, function (value, key) {
                    var foundBilling = false;
                    angular.forEach(vm.receivingClients, function (client, key) {
                        if (value.id == client.houseId) {
                            value.hasBilling = true;
                            foundBilling = true;
                        }
                    });
                    if (!foundBilling) {
                        value.hasBilling = false;
                    }
                    vm.housesDesorder.push(value);
                });
                vm.housesDesorder.sort(function (a, b) {
                    a.housenumber.localeCompare(b.housenumber, undefined, {numeric: true, sensitivity: 'base'});
                });
                vm.houses = vm.housesDesorder;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
